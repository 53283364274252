video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* position: fixed;*/
    z-index: -1;
}

.me-image-box-CV {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: gray;
    overflow: hidden;
    border: 2pt solid #195ede;
    position: absolute;
    top: 20%;
    left: 5%;
    margin-bottom: 50px;
}

.me-image-box-CV img {
    width: 100%;
    height: 100%;
}

.me-image-box {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: gray;
    overflow: hidden;
    border: 2pt solid #195ede;
    position: absolute;
    top: 20%;
    left: 5%;
}

.hero-container .me-image-box {
    width: 180px;
    height: 180px;
}

.hero-container .me-image-box img {
    /* width: 100% !important;*/
    width: 180px;
    height: 180px;
}

.hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    background: url('../../public/images/desk1.jpg') center center/cover no-repeat;
    height: 110vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container>h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container>p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 2000px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -150px;
    }
    .hero-container .me-image-box img {
        /* width: 100% !important;*/
        width: 220px;
        height: 220px;
    }
    .me-image-box-CV img {
        width: 220px;
        height: 220px;
    }
    .me-image-box-CV {
        width: 220px;
        height: 220px;
    }
    .hero-container .me-image-box {
        width: 220px;
        height: 220px;
    }
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -150px;
    }
    .hero-container .me-image-box img {
        /* width: 100% !important;*/
        width: 180px;
        height: 180px;
    }
    .me-image-box-CV img {
        width: 180px;
        height: 180px;
    }
    .me-image-box-CV {
        width: 180px;
        height: 180px;
    }
    .hero-container .me-image-box {
        width: 180px;
        height: 180px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }
    .hero-container>p {
        font-size: 30px;
    }
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    .btn {
        width: 100%;
    }
    .hero-container .me-image-box img {
        /* width: 100% !important;*/
        width: 130px;
        height: 130px;
    }
    .hero-container .me-image-box {
        width: 130px;
        height: 130px;
    }
    .me-image-box {
        left: 3%;
    }
    .me-image-box-CV img {
        /* width: 100% !important;*/
        width: 130px;
        height: 130px;
    }
    .me-image-box-CV {
        width: 130px;
        height: 130px;
    }
    .me-image-box-CV {
        left: 5%;
        margin-top: 20%;
    }
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

.home,
.projects,
.company,
.contactme,
.cartes {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.projects,
.cartes {
    background-image: url('../public/images/img-project.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

.company {
    background-image: url('../public/images/img-1.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

.contactme {
    background-image: url('../public/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
}

#content {
    /*width: 80%;*/
    padding-left: 10%;
    padding-right: 10%;
    overflow-x: hidden;
    min-height: 100px;
    display: block;
    overflow: hidden;
    background-size: 500px, 500px;
}

h2 {
    margin-top: 35px;
    cursor: default;
    text-align: center;
    padding: 10px;
}

.cv #content .personal-infos {
    padding-bottom: 10px;
    display: block;
    margin-top: 10px;
    margin-left: 150px;
    margin-bottom: 100px;
}

.curriculum {
    margin-top: 50px;
}

.cv #content h3 {
    font-size: 16pt;
    font-weight: normal;
    text-align: center;
    padding: 10px;
}

.cv-block .interests h3 {
    text-align: left !important;
}

.cv-block .voluntary-activities h3 {
    text-align: left !important;
}

.cv-block .driving-licenses h3 {
    text-align: left !important;
}

h3 {
    color: #ec9a29;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.cv #content p.timeframe {
    font-style: italic;
    font-size: 11pt;
}

.cv #content .skills h4 {
    color: #43AA8B;
    font-style: italic;
    font-weight: lighter;
    font-size: 13pt;
    border-bottom: 1pt solid;
    margin-bottom: 10px;
}

.fixed-width-70 {
    float: left;
    width: 70px;
    font-weight: bold;
    text-align: right;
    padding-right: 10px;
}

.cv-internal-block {
    margin-bottom: 8px;
}

#what {
    font-size: 65px;
    position: fixed;
    right: 0.3em;
    bottom: 0.3em;
    z-index: 120;
}

.hero-trailer {
    width: 80%;
    margin: 0px auto;
}